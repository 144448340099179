<template>
  <div>
    <div class="form">
      <ms-input
        type="password"
        v-model="form.currentPassword"
        :validator="$v.form.currentPassword"
        :options="{ 'show-password': true }"
        placeholder="Current Password"
      />

      <ms-input
        type="password"
        v-model="form.newPassword"
        :validator="$v.form.newPassword"
        :options="{ 'show-password': true }"
        placeholder="New Password"
      />

      <ms-input
        type="password"
        v-model="form.confirmNewPassword"
        :validator="$v.form.confirmNewPassword"
        :options="{ 'show-password': true }"
        placeholder="Confirm New Password"
      />

      <el-button
        type="success"
        size="large"
        class="w-full gradient mt-20"
        round
        @click="submit()">
        Confirm
      </el-button>

    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import { passwordValidation } from '@/components/common/validations'

import navigation from './navigation'

export default {
  data () {
    return {
      form: {
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null
      }
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Change Password'
    })

    this.$store.commit('page/setTabs', navigation)
  },

  methods: {
    async submit () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      const response = await this.$store.dispatch('users/action', {
        id: this.user.id,
        action: 'ChangePassword',
        payload: this.form
      })
      if (response?.action?.error) {
        this.$notify.error(response.action.error)
        return
      }
      this.$notify.success('Password changed successfully.')
      // DO CHANGE PASSWORD
      // try {
      // } catch {
      // }
    }
  },

  validations () {
    return {
      form: {
        currentPassword: { required },
        newPassword: { required, passwordValidation },
        confirmNewPassword: { required, sameAs: sameAs('newPassword') }
      }
    }
  }
}
</script>
